import React, { useState } from "react";

// seo
import Seo from "../components/Seo";

// components
import AboutUsDetail from "../components/AboutUs/AboutUsDetail";
import ExecutiveTeam from "../components/AboutUs/ExecutiveTeam";
import GlobalLeadershipTeam from "../components/AboutUs/GlobalLeadershipTeam";
import IndiaLeadershipTeam from "../components/AboutUs/IndiaLeadershipTeam";
import TechnologyTeam from "../components/AboutUs/TechnologyTeam";
import GetInTouch from "../components/Home/GetInTouch/GetInTouch";
import MedicalAdvisorBoard from "../components/AboutUs/MedicalAdvisorBoard";
import OurInvestors from "../components/AboutUs/OurInvestors";
import Advisors from "../components/AboutUs/Advisors";

const AboutUsPage = () => {
  const [openAboutUsModal, setOpenAboutUsModal] = useState(false);
  const [payload, setPayload] = useState(null);

  const handleAboutUsModal = data => {
    setPayload(data);
    handleModal();
  };

  const handleModal = () => {
    setOpenAboutUsModal(!openAboutUsModal);
  };

  return (
    <>
      <Seo
        title="About us | Reverse Diabetes, Obesity, PCOS & More"
        description="AI-driven treatment to reverse Diabetes, Hypertension, Obesity & Other Chronic Conditions. 97% reduced blood sugar & meds, 98% eliminated symptoms. Start today."
      />
      {openAboutUsModal ? (
        <AboutUsDetail payload={payload} handleBack={handleModal} />
      ) : (
        <>
          {/* <AboutUsBanner /> */}
          <GlobalLeadershipTeam handleAboutUsModal={handleAboutUsModal} />
          {/* <ExecutiveMember handleAboutUsModal={handleAboutUsModal} /> */}
          <IndiaLeadershipTeam handleAboutUsModal={handleAboutUsModal} />
          <TechnologyTeam handleAboutUsModal={handleAboutUsModal} />
          <ExecutiveTeam handleAboutUsModal={handleAboutUsModal} />
          <Advisors handleAboutUsModal={handleAboutUsModal} />
          <MedicalAdvisorBoard handleAboutUsModal={handleAboutUsModal} />
          <OurInvestors />
          <GetInTouch />
        </>
      )}
    </>
  );
};

export default AboutUsPage;
